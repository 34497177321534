<template>
  <div id="app">
  
    <router-view/>
  </div>
</template>
<script>

export default {

created(){
  document.title = "消息订阅"
}
}
</script>


<style lang="scss">

</style>
