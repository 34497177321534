<template>
  <div :style="{ width: mywidth }">
    <div class="top">关注"赋客宝"微信公众号</div>
    <div class="img" >
      <div style="width: 50%; height: 50%" v-if="!show"></div>
      <!-- <img :src="image" alt="" style="width: 50%; height: 50%" v-else /> -->
      <img src="../assets/20220516162917.jpg" alt="" style="width: 50%; height: 50%" v-else />
    </div>
    <div
      style="
        width: 100%;
        margin: 20px 0;
        display: flex;
        justify-content: center;
        color: #3880f7;
      "
    >
      长按二维码识别关注
    </div>
    <div style="display: flex; justify-content: center; margin-top: 50px;cursor: pointer;">
        <van-button
          round
          type="info"
          style="width: 80%"
          @click="edit"
          
          >下一步</van-button
        >
      </div>
  </div>
</template>

<script>
export default {
  name: "WeixinAboutView",
  data() {
    return {
      mywidth: "",
      image: "",
      show : false
    };
  },

  mounted() {},

  methods: {
    edit(){
      this.$api.getLog().then((res) => {
        if (res.data.subscribe == 1) {
          // 已经订阅
          this.$router.push("/")
        } else {
           this.$toast('请先关注公众号！');
        }
      });
    }
  },
  created() {
    let k = document.documentElement.clientWidth;
    this.mywidth = k + "px";
    this.$api.CodeLink().then((res) => {
      this.image = res.data.url;
      this.show = true
    });

  },
};
</script>

<style lang="scss" scoped>
.top {
  width: 100%;
  margin: 0 auto;
  font-size: 16px;
  color: #252a48;
  padding: 20px 0;
  display: flex;
  justify-content: center;
}
.img {
  width: 100%;
  margin: 100px 0;
  display: flex;
  justify-content: center;
}
</style>