import axios from 'axios'; // 引入axios

const baseUrl = 'https://fb.crmeb.fooktech.cn'

axios.defaults.baseURL = baseUrl

axios.defaults.timeout = 10000;//响应时间
// 请求头部
axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded;charset=UTF-8';

const shop = localStorage.getItem("ID")


// 响应拦截器
axios.interceptors.response.use(
    response => {
        if (response.data.code === 200) {
            return Promise.resolve(response);
        } else if (response.data.code === 999999 || !localStorage.getItem("token")) {
            // 跳转
            axios.post(baseUrl + "/api/front/weChat/getLoginLink").then((res) => {
                location.href = res.data.data
            })
        } else {
            return Promise.reject();
        }
    },

);
// "shop-id": localStorage.getItem("ID") ? localStorage.getItem("ID") : 0
export function axos(url, parmas, method) {
    return new Promise((resolve, reject) => {
        axios({
            method: method || "GET",
            url: "/api/" + url,
            data: parmas,
            headers: {
                "Authorization": localStorage.getItem("token") ? localStorage.getItem("token") : "",
                "shop-id": shop,
            }

        }).then(res => {
            resolve(res.data)
        }).catch(err => {
            reject(err.data)
        })
    })
}





