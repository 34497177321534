import { axos } from "./http"


export default {
    // 获取授权跳转链接
    // getLoginLink(data) {
    //     return axos("front/weChat/getLoginLink", data, "post")

    // },
    // 用户是否关注公众号
    getLog(data) {
        return axos("front/weChat/isSubscribe", data, "post")
    },
    // 获取用户模板消息订阅信息
    getSubscribeInfo(data) {
        return axos("front/weChat/getSubscribeInfo", data, "POST")
    },
    // 编辑用户模板消息订阅信息
    SubscribeInfo(data) {
        return axos("front/weChat/editSubscribeInfo", data, "post")
    },
    // 用户登录
    login(data) {
        return axos("front/weChat/login", data, "post")
    },
    // 获取二维码
    CodeLink(data) {
        return axos("front/weChat/subscribeQrCodeLink", data, "post")
    }

}
